import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LOGO from '../../images/main-logo.png'
import Arrow from '../../images/nav-icons/arrow-down.svg'
import China from '../../images/nav-icons/china.svg'
import HamburgerMenu from '../../images/nav-icons/hamburger-menu.svg'
import Japan from '../../images/nav-icons/japan.svg'
import LinkIcon from '../../images/nav-icons/link.svg'
import USA from '../../images/nav-icons/united-states.svg'
import { WalletButton } from '../WalletButton'
import '@reach/menu-button/styles.css'
import './Nav.scss'

interface NavProps {
  path: string
}

export const Nav = (props: NavProps) => {
  const [open, setOpen] = useState(false)
  const [language, setLanguage] = useState('en')
  const { t, i18n } = useTranslation()

  const [openUniverse, setOpenUniverse] = useState(false)
  const navMenuRef = useRef<HTMLUListElement>(null)

  const languageRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])
  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
    setLanguage(language)
    setOpen(false)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        setOpenUniverse(false)
      }
    }

    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setOpenUniverse])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setOpen])

  return (
    <StyledNav>
      <StyledMobileMenu>
        <StyledMobileMenuButton>
          <img src={HamburgerMenu} alt="Menu" />
        </StyledMobileMenuButton>
        <StyledMobileMenuList>
          <StyledMobileMenuItem
            onSelect={() => navigate(`/tokenomics`)}
            to="/tokenomics"
          >
            {t('token')}
          </StyledMobileMenuItem>
          <StyledMobileMenuItem onSelect={() => navigate(`/game-theory`)}>
            {t('regCaseGameTheory')}
          </StyledMobileMenuItem>
          <StyledMobileMenuItem onSelect={() => navigate(`/chainbinders`)}>
            {t('chainbinders')}
          </StyledMobileMenuItem>
          <StyledMobileMenuItem onSelect={() => navigate(`/universe/lore`)}>
            {t('lore')}
          </StyledMobileMenuItem>
          <StyledMobileMenuItem onSelect={() => navigate(`/universe/gallery`)}>
            {t('gallery')}
          </StyledMobileMenuItem>
          <StyledMobileMenuItem onSelect={() => navigate('/my-collection')}>
            {t('myCollectionNav')}
          </StyledMobileMenuItem>
        </StyledMobileMenuList>
      </StyledMobileMenu>

      <StyledLinkLogo to="/">
        <StyledLogoImg src={LOGO} />
      </StyledLinkLogo>
      <StyledNavUl>
        <StyledLi>
          <Link
            id={
              props.path.includes('my-collection')
                ? 'nav-link__active'
                : 'nav-link'
            }
            to="/my-collection"
          >
            {t('myCollectionNav')}
          </Link>
        </StyledLi>
        <StyledLi>
          <Link
            id={props.path === '/tokenomics' ? 'nav-link__active' : 'nav-link'}
            to="/tokenomics"
          >
            {t('token')}
          </Link>
        </StyledLi>
        <StyledLi>
          <Link
            id={props.path === '/game-theory' ? 'nav-link__active' : 'nav-link'}
            to="/game-theory"
          >
            {t('regCaseGameTheory')}
          </Link>
        </StyledLi>

        <LanguageButton
          onClick={() => setOpenUniverse(!openUniverse)}
          id={
            props.path.includes('/universe') ||
            props.path.includes('/chainbinders')
              ? 'nav-link__active'
              : 'nav-link'
          }
        >
          {t('universe')} <img src={Arrow} alt="arrow down" />
          {openUniverse && (
            <DropDownMenu ref={navMenuRef}>
              <StyledLink to="/universe/lore">{t('lore')}</StyledLink>
              <StyledLink to="/chainbinders">{t('chainbinders')}</StyledLink>
              <StyledLink to="/universe/gallery">{t('gallery')}</StyledLink>
            </DropDownMenu>
          )}
        </LanguageButton>

        <StyledLi>
          <StyledNavHref
            href="https://dokidoki.com/"
            target="_blank"
            color="#FF97C3"
          >
            DokiDoki <img src={LinkIcon} alt="" />
          </StyledNavHref>
        </StyledLi>
      </StyledNavUl>

      <SubGrid>
        <LanguageButton onClick={() => setOpen(!open)}>
          {language === 'en' ? (
            <StyledLanguageIcon src={USA} />
          ) : language === 'ja' ? (
            <StyledLanguageIcon src={Japan} />
          ) : (
            <StyledLanguageIcon src={China} />
          )}
          <img src={Arrow} alt="arrow down" />
          {open && (
            //@ts-ignore
            <DropDownLanguageMenu ref={languageRef}>
              <StyledDropDownIcon
                onClick={() => handleLanguageChange('en')}
                src={USA}
              />
              <StyledDropDownIcon
                src={Japan}
                onClick={() => handleLanguageChange('ja')}
              />
              <StyledDropDownIcon
                src={China}
                onClick={() => handleLanguageChange('zh')}
              />
            </DropDownLanguageMenu>
          )}
        </LanguageButton>

        <WalletButton />
      </SubGrid>
    </StyledNav>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  &:hover {
    background: #00ffc2;
    color: black !important;
  }
`

const SubGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 2fr;
  }
`

const StyledDropDownIcon = styled.img`
  width: 4.425rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  &:hover {
    background: #00ffc2;
    color: black !important;
  }
`

const StyledLanguageIcon = styled.img`
  width: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #00ffc2;
    color: black !important;
  }
`

const DropDownMenuItem = styled.li``

const DropDownLanguageMenu = styled.div`
  position: absolute;
  background: black;
  width: inherit;
  margin-right: 8px;

  bottom: -165px;
`

const DropDownMenu = styled.ul`
  position: absolute;
  background: black;
  width: max-content;
  bottom: -175px;
`

const LanguageButton = styled.div`
  height: 100%;
  color: white;
  cursor: pointer;
  justify-self: center;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;

  justify-self: start;

  &:hover {
    background: #00ffc2;
  }

  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: flex;
  }
`

const StyledNav = styled.nav`
  z-index: 10;
  width: 100%;
  background: #010101;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.685);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.411);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);

  position: fixed;
  top: 0;
  @media (min-width: 200px) {
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1200px) {
    padding-right: 1rem;
    column-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledLinkLogo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 200px) {
    justify-self: center;
  }

  @media (min-width: 1200px) {
    justify-self: start;
  }
`

const StyledLogoImg = styled.img`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 10rem;

  @media (min-width: 750px) {
    width: 10rem;
  }

  @media (min-width: 1000px) {
    width: 15rem;
  }

  @media (min-width: 1200px) {
    width: 15rem;
    margin-left: 2rem;
  }
`

const StyledNavUl = styled.ul`
  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr) 2fr;
    column-gap: 1.5rem;
    align-items: center;
    white-space: nowrap;
    height: 100%;
  }
`

const StyledLi = styled.li`
  height: 100%;

  &:focus {
    margin-top: 2px;
    outline: 0.5px solid #00ffc2;
  }
`

const StyledNavHref = styled.a`
  width: 100%;
  font-size: 18px;
  background: transparent;
  border: none;
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
  }

  &:before {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    background: #00ffc2;
    content: '';
    opacity: 0;
    transition: all 0.3s;
    top: 0px;
    transform: translateY(10px);
  }

  &:after {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    background: #00ffc2;
    content: '';
    opacity: 0;
    transition: all 0.3s;
    bottom: 0px;
    transform: translateY(-10px);
  }

  &:hover:after {
    opacity: 1;
    transform: translateY(0px);
  }

  &:hover:before {
    opacity: 1;
    transform: translateY(0px);
  }
`

const StyledMobileMenu = styled(Menu)`
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledMobileMenuButton = styled(MenuButton)`
  width: 20%;
  padding-top: 5px;
  margin-left: 1rem;
  justify-self: start;
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledMobileMenuList = styled(MenuList)`
  width: 100%;
  height: 100%;
  background: black;
  padding: 2rem;
  position: relative;
  z-index: 4;
  margin-left: -1rem;

  &[data-reach-menu-list][data-reach-menu-items] {
    width: 100vw;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 1rem;

    &:focus {
      outline: none;
    }
  }
`

const StyledMobileMenuItem = styled(MenuItem)`
  font-size: 18px;
  border: 2px solid transparent;
  padding: 10px;
  color: #ffffff;

  &[data-reach-menu-item][data-selected] {
    color: #ffffff;
    background: #00ffc2;
  }
`
