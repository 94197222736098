import wrapWithLayout from './src/wrap-with-layout'
import wrapWithProvider from './src/wrap-with-provider'
/**
 * wrapPageElement renders every time the page changes —
 * wrapRootElement does not—making it ideal for complex page transitions,
 * or for stuff that need the page path, like an internationalization context provider for example.
 * On the other hand, because wrapRootElement doesn’t render when the page changes,
 * it’s a good fit for context providers that don’t need the page,
 * like theme or global application state providers. And that’s their biggest difference.
 */
export const wrapRootElement = wrapWithProvider

export const wrapPageElement = wrapWithLayout
