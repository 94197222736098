import { ProviderRpcError } from './errors'

export interface EthgasstationResponse {
  fast: number
  fastest: number
  safeLow: number
  average: number
  block_time: number
  blockNum: number
  speed: number
  safeLowWait: number
  avgWait: number
  fastWait: number
  fastestWait: number
}

// https://github.com/pedrouid/eip1193-provider/blob/master/src/types.ts
export interface ProviderMessage {
  type: string
  data: unknown
}

export interface ProviderInfo {
  chainId: string
}

export interface RequestArguments {
  method: string
  params?: unknown[] | object
}

export type ProviderChainId = string

export type ProviderAccounts = string[]

export interface EIP1102Request extends RequestArguments {
  method: 'eth_requestAccounts'
}

export interface SimpleEventEmitter {
  // add listener
  on(event: string, listener: any): void
  // add one-time listener
  once(event: string, listener: any): void
  // remove listener
  removeListener(event: string, listener: any): void
  // removeListener alias
  off(event: string, listener: any): void
}

export enum EIP1193ProviderEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  MESSAGE = 'message',
  CHAIN_CHANGED = 'chainChanged',
  ACCOUNTS_CHANGED = 'accountsChanged',
}

export interface EIP1193EventMapping {
  [EIP1193ProviderEvent.ACCOUNTS_CHANGED]: (accounts: ProviderAccounts) => void
  [EIP1193ProviderEvent.CHAIN_CHANGED]: (chainId: ProviderChainId) => void
  [EIP1193ProviderEvent.CONNECT]: (info: ProviderInfo) => void
  [EIP1193ProviderEvent.DISCONNECT]: (error: ProviderRpcError) => void
  [EIP1193ProviderEvent.MESSAGE]: (message: ProviderMessage) => void
}

export interface EIP1193Provider extends SimpleEventEmitter {
  // connection event
  on(
    event: EIP1193ProviderEvent.CONNECT,
    listener: (info: ProviderInfo) => void,
  ): void
  // disconnection event
  on(
    event: EIP1193ProviderEvent.DISCONNECT,
    listener: (error: ProviderRpcError) => void,
  ): void
  // arbitrary messages
  on(
    event: EIP1193ProviderEvent.MESSAGE,
    listener: (message: ProviderMessage) => void,
  ): void
  // chain changed event
  on(
    event: EIP1193ProviderEvent.CHAIN_CHANGED,
    listener: (chainId: ProviderChainId) => void,
  ): void
  // accounts changed event
  on(
    event: EIP1193ProviderEvent.ACCOUNTS_CHANGED,
    listener: (accounts: ProviderAccounts) => void,
  ): void
  // make an Ethereum RPC method call.
  request(args: RequestArguments): Promise<unknown>
}

export interface IEthereumProvider extends EIP1193Provider {
  // legacy alias for EIP-1102
  enable(): Promise<ProviderAccounts>
}
