import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks'
import { gql, request } from 'graphql-request'

export interface Ownership {
  balance: string
}

export interface NFT {
  tokenId: string
  ownership: Ownership
}
interface RawNFT {
  tokenId: string
  ownership: Ownership[]
}

export async function queryForBalances(
  endpoint: string,
  owner: string,
): Promise<{
  nfts: NFT[]
}> {
  const query = gql`
    query getOwnerships($owner: String!) {
      nfts(first: 1000) {
        tokenId
        ownership(where: { owner: $owner }) {
          balance # The number of NFTs this owner has
        }
      }
    }
  `
  const variables = { owner }
  const data: {
    nfts: RawNFT[]
  } = await request(endpoint, query, variables)

  const mapped: NFT[] = data.nfts.map((n) => ({
    tokenId: n.tokenId,
    ownership: n.ownership[0] || { balance: '0' },
  }))

  return { nfts: mapped }
}

export interface Rarity {
  id: string
  ethBalance: string
  poolRatio: string
  azukiAmountRequiredToMelt: string
  nftAmount: string
}
export interface Chainbinder {
  id: string
  supply: string
  amountBurned: string
  rarity: Rarity
}

/**
 * Only applies to L2, the stats arent available on L1 since this is drawn from the melt contract
 * @param endpoint
 * @returns
 */
export async function queryForChainbindersStats(
  endpoint: string,
): Promise<{ chainbinders: Chainbinder[] }> {
  const query = gql`
    query getChainbindersStats {
      chainbinders(first: 1000) {
        id # Token ID
        supply # Circulating supply
        amountBurned # Amount melted to redeem ETH
        rarity {
          id
          ethBalance # Amount of ETH in the reserve pool for this rarity
          poolRatio # The ratio of ETH in the reseve pool for this rarity
          azukiAmountRequiredToMelt # Amount of Azuki token required to melt this NFT
          nftAmount # Amount of NFTs in this rarity, burned NFTs reduce this quantity
        }
      }
    }
  `
  const data = await request(endpoint, query)
  return data
}

interface NftTrait {
  trait_type: 'Chainbinders' | 'Rarity' | 'Artist' | 'Brand' | 'Series'
  value: string
}

export interface RawStaticNft {
  isSecret: boolean
  rarityType: string
  gatsbyPath: string
  tokenId: string
  name: string
  description: string
  max_quantity: string
  animation_url?: string
  image: string
  localVideo: {
    publicURL: string
    videoScreenshots: FileNode[]
  }
  external_url?: string
  attributes: NftTrait[]
}

export interface StaticQueryNft {
  allBinderNft: {
    nodes: RawStaticNft[]
  }
}
