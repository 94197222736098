import * as ethers from 'ethers'
import { AzukiToken, AzukiToken__factory } from '../../ethers-contracts'
import { web3Config } from './config'
export class AzukiService {
  private contract: AzukiToken
  constructor(...params: Parameters<typeof AzukiToken__factory['connect']>) {
    this.contract = AzukiToken__factory.connect(...params)
  }

  public async getAllowance(fromAccount: string) {
    const allowance = await this.contract.allowance(
      fromAccount,
      web3Config.UNBIND_ADDRESS,
    )
    return allowance
  }

  public async tx() {
    return await this.contract.approve(
      web3Config.UNBIND_ADDRESS,
      ethers.constants.MaxUint256,
    )
  }

  public async estimateGas() {
    return await this.contract.estimateGas.approve(
      web3Config.UNBIND_ADDRESS,
      ethers.constants.MaxUint256,
    )
  }
}
