import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import LogOut from '../../images/log-out.svg'
import MetaMask from '../../images/metamask.svg'
import { useAppDispatch } from '../../store'
import {
  selectWeb3Account,
  selectWeb3Loading,
  SignerLoading,
  signerSlice,
  SignerType,
} from '../../store/web3/signer'

export const WalletButton: React.FC = () => {
  const { t } = useTranslation()
  const web3Loading = useSelector(selectWeb3Loading)
  const address = useSelector(selectWeb3Account)
  const dispatch = useAppDispatch()
  if (web3Loading === SignerLoading.SELECTED) {
    return null
  }

  const begginningAddress = address?.substring(0, 7)
  const endAddress = address?.substring(35, address.length)
  const truncated = begginningAddress + '...' + endAddress

  const text = address ? truncated : t('Connect')

  return (
    <>
      <DesktopButton
        disabled={!!address}
        onClick={() => {
          dispatch(
            signerSlice.actions.signerSelected({
              signerType: SignerType.METAMASK,
            }),
          )
        }}
      >
        <Text>
          {text}

          {address ? null : <MetaMaskIcon src={MetaMask} alt="metamask icon" />}
        </Text>

        {address && (
          <LogOutButton>
            <StyledImg
              onClick={() => dispatch(signerSlice.actions.logout())}
              src={LogOut}
              alt="log out"
            />
          </LogOutButton>
        )}
      </DesktopButton>

      <MobileButton
        onClick={() => {
          if (address) {
            dispatch(signerSlice.actions.logout())
          } else {
            dispatch(
              signerSlice.actions.signerSelected({
                signerType: SignerType.METAMASK,
              }),
            )
          }
        }}
      >
        {address ? (
          <StyledImg src={LogOut} alt="log out" />
        ) : (
          <StyledImg src={MetaMask} alt="log in" />
        )}
      </MobileButton>
    </>
  )
}

const MetaMaskIcon = styled.img`
  width: 2rem;
  margin-left: 1rem;
`

const MobileButton = styled.button`
  justify-self: end;

  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  margin-right: 1rem;
  &:hover {
    background: #ff9900;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const LogOutButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  background: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  &:hover {
    background: #ff9900;
  }
`

const StyledImg = styled.img`
  @media (min-width: 200px) {
    display: inline-block;
    width: 1rem;
  }

  @media (min-width: 750px) {
    display: inline-block;
    width: 1rem;
  }
`

const Text = styled.div`
  color: black;
  @media (min-width: 200x) {
    display: none;
  }
  @media (min-width: 1200px) {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const DesktopButton = styled.button`
  display: flex;
  align-items: center;
  justify-self: end;
  cursor: pointer;

  color: black;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  border-radius: 18px;
  font-weight: 400;
  font-family: 'Roboto';
  font-size: 1rem;
  box-sizing: border-box;

  z-index: 1;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  border-radius: 2.5rem / 100%;

  background: rgb(0, 255, 194);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(0, 234, 223, 1) 100%
  );

  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 700px) {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  @media (min-width: 1200px) {
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`
