import { parseUnits } from '@ethersproject/units'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { ActionsObservable, Epic } from 'redux-observable'
import { concat, from, merge, of, timer } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { RootState } from '..'
import { getFastGasPriceForMaticMainnet } from '../../api/web3'
import { AzukiService } from './azuki.service'
import { web3Config } from './config'
import { MomijiService } from './momiji.service'
import { UnbindService } from './unbind.service'
import { web3Service } from './web3.service'

/**
 * The current loading state depending on user actions
 */
export enum UnbindLoading {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESSFUL = 'successful',
  ERRORED = 'errored',
}

interface UnbindStatsInit extends UnbindStatsUpdate {}

interface UnbindStatsUpdate {
  ethUsdPrice: number
  maticUsdPrice: number
  azukiUsdPrice: number
  isStarted: boolean
}

export interface UnbindStats extends UnbindStatsInit {
  errorMessage?: string
  loading: UnbindLoading
}

const INITIAL_STATE: UnbindStats = {
  loading: UnbindLoading.IDLE,
  errorMessage: undefined,
  isStarted: false,

  ethUsdPrice: 0,
  azukiUsdPrice: 0,
  maticUsdPrice: 0,
}

export const unbindStatsSlice = createSlice({
  name: 'unbindStats',
  initialState: INITIAL_STATE,
  reducers: {
    INIT_REQUESTED: (state) => {
      state.loading = UnbindLoading.PENDING
      state.errorMessage = undefined
    },
    INIT_SUCCEEDED: (state, action: PayloadAction<UnbindStatsInit>) => {
      return {
        ...state,
        loading: UnbindLoading.SUCCESSFUL,
        errorMessage: undefined,
        ...action.payload,
      }
    },
    INIT_FAILED: (state, action: PayloadAction<{ errorMessage?: string }>) => {
      state.loading = UnbindLoading.ERRORED
      state.errorMessage = action.payload.errorMessage
    },
    POLL_UPDATE: (state, action: PayloadAction<UnbindStatsUpdate>) => {
      return { ...state, ...action.payload }
    },
    POLL_FAILED: () => {
      // noop
    },
    STOPPED: (state) => {
      state = INITIAL_STATE
    },
  },
})

interface UnbindGasState {
  gasPrice: string

  estimatedGasLoading: UnbindLoading
  estimatedGasErrorMessage?: string
  estimatedGas: string
}

const UNBIND_GAS_INITIAL_STATE: UnbindGasState = {
  gasPrice: '1250000000000', // 1250 Gwei starting price

  estimatedGasLoading: UnbindLoading.IDLE,
  estimatedGas: '0',
  estimatedGasErrorMessage: undefined,
}

export const unbindGasSlice = createSlice({
  name: 'unbindGas',
  initialState: UNBIND_GAS_INITIAL_STATE,
  reducers: {
    INIT_GAS_PRICE_POLLING: () => {},
    GAS_PRICE_UPDATE_SUCCEEDED: (
      state,
      action: PayloadAction<{ gasPrice: string }>,
    ) => {
      state.gasPrice = action.payload.gasPrice
    },
    GAS_PRICE_UPDATE_FAILED: () => {
      // noop
    },
    STOP_GAS_PRICE_POLLING: () => {},
    GAS_ESTIMATE_REQUESTED: (
      state,
      _: PayloadAction<
        | { nftId: string; amount: number; txType: TransactionType.UNBIND }
        | {
            txType:
              | TransactionType.APPROVE_AZUKI
              | TransactionType.APPROVE_MOMIJI
          }
      >,
    ) => {
      state.estimatedGasLoading = UnbindLoading.PENDING
    },
    GAS_ESTIMATE_SUCCEEDED: (
      state,
      action: PayloadAction<{ gasCost: string }>,
    ) => {
      state.estimatedGasLoading = UnbindLoading.SUCCESSFUL
      state.estimatedGas = action.payload.gasCost
    },
    GAS_ESTIMATE_FAILED: (
      state,
      action: PayloadAction<{ errorMessage?: string }>,
    ) => {
      state.estimatedGasLoading = UnbindLoading.ERRORED
      state.estimatedGasErrorMessage = action.payload.errorMessage
    },
  },
})

export const selectUnbindStats = (state: RootState) => state.web3.unbindStats
export const selectEthUsdRatio = createSelector(
  selectUnbindStats,
  (s) => s.ethUsdPrice,
)
export const selectUnbindIsStarted = createSelector(
  selectUnbindStats,
  (state) => state.isStarted,
)
export const selectUnbindStatsLoading = createSelector(
  selectUnbindStats,
  (state) =>
    state.loading === UnbindLoading.PENDING ||
    state.loading === UnbindLoading.ERRORED,
)
export const selectGasSelf = (state: RootState) => state.web3.unbindGas
export const selectGasPending = createSelector(
  selectGasSelf,
  (state) =>
    state.estimatedGasLoading === UnbindLoading.PENDING ||
    state.estimatedGasLoading === UnbindLoading.ERRORED,
)
export const updateGasEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const {
    GAS_ESTIMATE_REQUESTED,
    GAS_ESTIMATE_SUCCEEDED,
    GAS_ESTIMATE_FAILED,
  } = unbindGasSlice.actions

  return action$.pipe(
    filter(GAS_ESTIMATE_REQUESTED.match),
    debounceTime(250),
    switchMap(({ payload }) => {
      const signer = web3Service.getSigner()
      const tx =
        payload.txType === TransactionType.UNBIND
          ? new UnbindService(web3Config.UNBIND_ADDRESS, signer).estimateGas(
              payload.nftId,
              payload.amount,
            )
          : payload.txType === TransactionType.APPROVE_AZUKI
          ? new AzukiService(
              web3Config.TOKENS_BY_SYMBOL.AZUKI.L2,
              signer,
            ).estimateGas()
          : new MomijiService(web3Config.MOMIJI_ADDRESS, signer).estimateGas()

      return from(tx).pipe(
        map((gasCost) =>
          GAS_ESTIMATE_SUCCEEDED({ gasCost: gasCost.toString() }),
        ),
        catchError((e: Error) => {
          console.error('[updateGasEpic][GAS_ESTIMATE_FAILED]', e)
          return of(GAS_ESTIMATE_FAILED({ errorMessage: e.message }))
        }),
      )
    }),
  )
}
export const updateGasPriceEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const {
    INIT_GAS_PRICE_POLLING,
    GAS_PRICE_UPDATE_SUCCEEDED,
    GAS_PRICE_UPDATE_FAILED,
    STOP_GAS_PRICE_POLLING,
  } = unbindGasSlice.actions

  return action$.pipe(
    filter(INIT_GAS_PRICE_POLLING.match),
    mergeMap(() => {
      const stop$ = action$.pipe(filter(STOP_GAS_PRICE_POLLING.match))
      return timer(0, 10000).pipe(
        switchMap(() => {
          return from(getFastGasPriceForMaticMainnet()).pipe(
            distinctUntilChanged(),
            map((gasPrice) =>
              GAS_PRICE_UPDATE_SUCCEEDED({
                gasPrice: parseUnits(gasPrice.toString(), 'gwei').toString(),
              }),
            ),
            catchError((e) => {
              console.error('[updateGasPriceEpic][FAILED]', e)
              return of(GAS_PRICE_UPDATE_FAILED())
            }),
          )
        }),
        takeUntil(stop$),
      )
    }),
  )
}

export const updateUnbindStatsEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const {
    INIT_FAILED,
    INIT_REQUESTED,
    INIT_SUCCEEDED,
    POLL_UPDATE,
    POLL_FAILED,
    STOPPED,
  } = unbindStatsSlice.actions

  return action$.pipe(
    filter(INIT_REQUESTED.match),
    mergeMap(() => {
      const unbindService = new UnbindService(
        web3Config.UNBIND_ADDRESS,
        web3Service.l2Provider,
      )
      const stop$ = action$.pipe(filter(STOPPED.match))

      const init = from(unbindService.init()).pipe(
        map(INIT_SUCCEEDED),
        catchError((e: Error) => of(INIT_FAILED({ errorMessage: e.message }))),
      )
      // turn this into a tick based watcher
      // so we can have network dependent updates be based off of
      // the same tick, like unbind price updates
      const shouldUpdate$ = emitOnIntervalOrTx(action$)
      const poller = shouldUpdate$.pipe(
        mergeMap(() =>
          from(unbindService.poll()).pipe(
            map(POLL_UPDATE),
            catchError((e) => {
              console.error('[updateUnbindStatsEpic][POLL_FAILED]', e?.message)
              return of(POLL_FAILED)
            }),
          ),
        ),
        distinctUntilChanged((a, b) => {
          if (a.type !== b.type) {
            return false
          }
          if (unbindStatsSlice.actions.POLL_FAILED.match(b)) {
            return true
          }

          return JSON.stringify(a) === JSON.stringify(b)
        }),
        takeUntil(stop$),
      )
      return concat(init, poller)
    }),
  )
}

export enum TransactionLoading {
  IDLE = 'idle',
  PENDING = 'pending',
  BROADCASTED = 'broadcasted',
  MINED = 'mined',
  ERROR = 'error',
}

export enum TransactionType {
  APPROVE_MOMIJI = 'approve/momiji',
  APPROVE_AZUKI = 'approve/azuki',
  UNBIND = 'unbind',
}

export interface UnbindTxState {
  loading: TransactionLoading
  txType?: TransactionType
  hash?: string
}

const INITIAL_UNBIND_TX_STATE: UnbindTxState = {
  loading: TransactionLoading.IDLE,
  hash: undefined,
}

export const unbindTxSlice = createSlice({
  name: 'unbindTx',
  initialState: INITIAL_UNBIND_TX_STATE,
  reducers: {
    TX_REQUESTED: (
      state,
      action: PayloadAction<
        | {
            txType: TransactionType.APPROVE_AZUKI
          }
        | {
            txType: TransactionType.APPROVE_MOMIJI
          }
        | {
            txType: TransactionType.UNBIND
            nftId: string
            amount: number
          }
      >,
    ) => {
      state.txType = action.payload.txType
      state.hash = undefined
      state.loading = TransactionLoading.PENDING
    },
    TX_FAILED: (state) => {
      state.loading = TransactionLoading.ERROR
    },
    TX_BROADCASTED: (state, action: PayloadAction<{ hash: string }>) => {
      state.loading = TransactionLoading.BROADCASTED
      state.hash = action.payload.hash
    },
    TX_MINED: (state) => {
      state.loading = TransactionLoading.MINED
    },
    TX_CLEARED: () => {
      return INITIAL_UNBIND_TX_STATE
    },
  },
})

export const selectUnbindTxSelf = (state: RootState) => state.web3.unbindTx

export const unbindTxEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const {
    TX_BROADCASTED,
    TX_FAILED,
    TX_MINED,
    TX_REQUESTED,
  } = unbindTxSlice.actions

  return action$.pipe(
    filter(TX_REQUESTED.match),
    mergeMap(({ payload }) => {
      const signer = web3Service.getSigner()
      const tx =
        payload.txType === TransactionType.UNBIND
          ? new UnbindService(web3Config.UNBIND_ADDRESS, signer).tx(
              payload.nftId,
              payload.amount,
            )
          : payload.txType === TransactionType.APPROVE_AZUKI
          ? new AzukiService(web3Config.TOKENS_BY_SYMBOL.AZUKI.L2, signer).tx()
          : new MomijiService(web3Config.MOMIJI_ADDRESS, signer).tx()

      return from(tx).pipe(
        mergeMap((tx) => {
          const broadcasted = of(TX_BROADCASTED({ hash: tx.hash }))
          const mined = from(tx.wait()).pipe(mapTo(TX_MINED()))

          return concat(broadcasted, mined)
        }),
        catchError((e: Error) => {
          console.error(`[unbindTxEpic][FAILED][${payload.txType}]`, e)
          return of(TX_FAILED())
        }),
      )
    }),
  )
}

type TxActions = typeof unbindTxSlice.actions
export function matchOnAnyTx(
  a: AnyAction,
): a is ReturnType<TxActions[keyof TxActions]> {
  const {
    TX_BROADCASTED,
    TX_CLEARED,
    TX_FAILED,
    TX_MINED,
    TX_REQUESTED,
  } = unbindTxSlice.actions

  return (
    TX_BROADCASTED.match(a) ||
    TX_CLEARED.match(a) ||
    TX_FAILED.match(a) ||
    TX_MINED.match(a) ||
    TX_REQUESTED.match(a)
  )
}

export function emitOnIntervalOrTx(action$: ActionsObservable<AnyAction>) {
  return merge(timer(0, 5000), action$.pipe(filter(matchOnAnyTx))).pipe(
    mapTo(null),
  )
}
