import detectEthereumProvider from '@metamask/detect-provider'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Axios from 'axios-observable'
import * as ethers from 'ethers'
import { from, fromEvent, merge, Observable } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { web3Config } from '../../store/web3/config'
import {
  NoEthereumProviderError,
  ProviderRpcError,
  UserLoggedOutError,
  UserRejectedRequestError,
} from './errors'
import {
  EIP1193EventMapping,
  EIP1193ProviderEvent,
  EthgasstationResponse,
} from './types'

interface Prices {
  AZUKI: { USD: number }
  MATIC: { USD: number }
  ETH: { USD: number }
}

export function getPrices(): Observable<Prices> {
  return Axios.get<Prices>(web3Config.MULTI_USD_URL).pipe(map((r) => r.data))
}

export interface AddTokenParams {
  address: string
  symbol: string
  decimals: number
  /**
   * A string url of the token logo
   */
  image: string
}
export async function addTokenToMetamask(
  metamaskProvider: ethers.providers.Web3Provider,
  { address, decimals, image, symbol }: AddTokenParams,
) {
  const wasAdded: boolean = await (metamaskProvider.provider as any).request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', // Initially only supports ERC20, but eventually more!
      options: {
        address, // The address that the token is at.
        symbol, // A ticker symbol or shorthand, up to 5 chars.
        decimals, // The number of decimals in the token
        image, // A string url of the token logo
      },
    },
  })

  return wasAdded
}

interface AddEthereumChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string // 2-6 characters long
    decimals: 18
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
}

export async function addMaticChainToMetamask(
  metamaskProvider: ethers.providers.Web3Provider,
): Promise<Error | undefined> {
  const params: AddEthereumChainParameter = {
    chainName: 'Matic Mainnet',
    chainId: '0x89',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://rpc-mainnet.matic.network',
      'wss://ws-mainnet.matic.network',
    ],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/'],
  }

  const error: any = await (metamaskProvider.provider as any)
    .request({
      method: 'wallet_addEthereumChain',
      params: [params],
    })
    .catch((e: Error) => e)

  return error
}

export function getFastGasPriceForEthereumMainnet() {
  const url = 'https://ethgasstation.info/api/ethgasAPI.json'
  const url2 =
    'https://data-api.defipulse.com/api/v1/egs/api/ethgasAPI.json?api-key=c44b0d310199eecb8a050e0ab1dbaebf62ab9ab62414c77dd7a47dcce321'

  return Axios.get<EthgasstationResponse>(url).pipe(
    catchError(() => {
      console.warn(
        '[getFastGasPriceForEthereumMainnet] Primary failed, falling back to defipulse...',
      )
      return Axios.get<EthgasstationResponse>(url2)
    }),
    map((r) => r.data.fast),
  )
}
export function getFastGasPriceForMaticMainnet() {
  const url = 'https://gasstation-mainnet.matic.network'

  return Axios.get<EthgasstationResponse>(url).pipe(map((r) => r.data.fast))
}

/**
 * Emit the new chain id whenever the provider changes it.
 *
 * Works with both metamask and walletconnect.
 *
 * Not sure if needed with walletconnect since you can specify multiple
 * chainids within the infra bridge by default.
 * @see https://github.com/ethers-io/ethers.js/issues/866
 * @see https://github.com/ethers-io/ethers.js/issues/899
 * @param provider The ethereum provider
 * @returns The new chain id
 */
export function chainChangedStream(
  provider: ethers.providers.Web3Provider,
): Observable<number> {
  const chainId = from(provider.getNetwork()).pipe(
    map((network) => network.chainId),
  )
  const chainChanged = merge(
    chainId,
    fromProviderEvent(provider, EIP1193ProviderEvent.CHAIN_CHANGED).pipe(
      map((chainId) => parseInt(chainId, 16)),
    ),
  )

  return chainChanged
}

/**
 * Emit a value whenever the provider's account changes.
 *
 * Works with both metamask and walletconnect
 * @param provider The ethereum provider
 * @returns The new account
 */
export function accountsChangedStream(
  provider: ethers.providers.Web3Provider,
): Observable<string> {
  return fromProviderEvent(
    provider,
    EIP1193ProviderEvent.ACCOUNTS_CHANGED,
  ).pipe(
    map((a) => {
      if (a.length !== 1) {
        // Metamask/walletconnect should always expose one account
        throw new UserLoggedOutError()
      }

      return a[0]
    }),
  )
}

/**
 * Emits the connected chainId when the provider is able is service rpc requests
 * @param provider The ethereum provider
 * @returns The chainId that the provider is connected to
 */
export function connectedStream(
  provider: ethers.providers.Web3Provider,
): Observable<number | undefined> {
  return fromProviderEvent(provider, EIP1193ProviderEvent.CONNECT).pipe(
    map((e) => (e?.chainId ? parseInt(e.chainId, 16) : undefined)),
  )
}

/**
 * Emits a closure reason when the provider has disconnected.
 * Note that disconnection means that the provider is unable to
 * service rpc requests.
 *
 * Works with metamask and walletconnect
 * @param provider The ethereum provider
 * @returns The closure reason
 */
export function disconnectedStream(
  provider: ethers.providers.Web3Provider,
): Observable<ProviderRpcError | undefined> {
  return fromProviderEvent(provider, EIP1193ProviderEvent.DISCONNECT).pipe(
    tap((reason) => {
      console.error('[disconnectedStream]', reason)
    }),
  )
}

/**
 * Request account view access from the provider.
 *
 * Used to allow metamask interaction from the user.
 * Not needed for walletconnect since it requires explicit
 * QR code scanning.
 *
 * @param provider The ethereum provider
 *
 * @throws A generic error or a UserRejectedRequestError
 *
 * If the user rejects account access due to EIP-1193 then the
 * UserRejectedRequestError will be thrown, otherwise the bubbled up
 * error will be thrown
 *
 *
 * @returns The users account that they allowed access on
 */
export async function requestMetamaskAccounts(
  provider: ethers.providers.Web3Provider,
): Promise<string> {
  try {
    const result: string[] = await (provider.provider as any).request({
      method: 'eth_requestAccounts',
    })

    if (result.length !== 1) {
      // Metamask should always expose one account
      throw new UserRejectedRequestError()
    }

    return result[0]
  } catch (e) {
    if (e?.code === 4001) {
      throw new UserRejectedRequestError()
    }
    throw e
  }
}

/**
 * Attempt to get a metamask injected provider
 *
 * @throws NoEthereumProviderError if any of the following conditions are met:
 * 1. There is no window.ethereum object
 * 2. There window.ethereum object did not originate from metamask
 *
 * There is a 3 second threshold for the object to be injected before timing out
 * and throwing an error due to reason #1.
 *
 * @returns The ethers wrapped metamask provider
 */
export async function getMetamaskProvider(): Promise<
  [ethers.providers.Web3Provider, ethers.Signer]
> {
  try {
    const eip1193Provider = await detectEthereumProvider({
      mustBeMetaMask: true,
    })
    const provider = new ethers.providers.Web3Provider(
      eip1193Provider as any,
      'any',
    )
    return [provider, provider.getSigner()]
  } catch {
    throw new NoEthereumProviderError()
  }
}

export async function getWalletConnectProvider(): Promise<
  [ethers.providers.Web3Provider, ethers.Signer]
> {
  //  Create WalletConnect Provider
  const eip1193Provider = new WalletConnectProvider({
    chainId: web3Config.L1.CHAIN_ID,
    rpc: {
      [web3Config.L1.CHAIN_ID]: web3Config.L1.RPC_URL,
      [web3Config.L2.CHAIN_ID]: web3Config.L2.RPC_URL,
    },
  })

  eip1193Provider.on = eip1193Provider.on.bind(eip1193Provider)
  const provider = new ethers.providers.Web3Provider(
    eip1193Provider as any,
    'any',
  )
  return [provider, provider.getSigner()]
}

export async function requestWalletConnectAccounts(
  provider: ethers.providers.Web3Provider,
): Promise<string> {
  const wcProvider = provider.provider
  if (!isWalletConnectProvider(wcProvider)) {
    throw Error(
      'Tried to request wallet connect accounts from a non-wallet connect provider',
    )
  }
  //  Enable session (triggers QR Code modal)
  await wcProvider.enable()
  const accounts = await provider.listAccounts()
  return accounts[0]
}

function isWalletConnectProvider(
  provider: unknown,
): provider is WalletConnectProvider {
  const p = provider as WalletConnectProvider

  return !!p.isWalletConnect
}

export function fromProviderEvent<
  E extends keyof EIP1193EventMapping = keyof EIP1193EventMapping
>(
  provider: ethers.providers.Web3Provider,
  event: E,
): Observable<Parameters<EIP1193EventMapping[E]>[0]> {
  return fromEvent(provider.provider as any, event)
}
