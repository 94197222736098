/* eslint-disable import/no-anonymous-default-export */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Footer } from './components/Footer/Footer'
import { Nav } from './components/Nav/Nav'
import { useAppDispatch } from './store'
import { cbStatsSlice } from './store/web3/collection.state.ts'
import { unbindStatsSlice } from './store/web3/unbind.side-effects'
import { Helmet } from 'react-helmet'

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
)

const FlexWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
  min-height: 100vh;

  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledMain = styled.main``

const App = (props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(unbindStatsSlice.actions.INIT_REQUESTED())
    return () => {
      dispatch(unbindStatsSlice.actions.STOPPED())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(cbStatsSlice.actions.INIT_POLLING_REQUESTED())

    return () => {
      dispatch(cbStatsSlice.actions.POLL_STOPPED())
    }
  }, [])

  return <StyledMain>{props.element}</StyledMain>
}

// props provide same data to Layout as Page element will get
// including location, data, etc - you don't need to pass it
export default ({ element, props }) => {
  const isSSR = typeof window === 'undefined'

  return !isSSR ? (
    <React.Suspense fallback={<Loader />}>
      <FlexWrapper>
        <Nav path={props.location.pathname} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Chainbinders</title>
          <link rel="canonical" href="https://chainbinders.dokidoki.com/" />
        </Helmet>
        <App element={element}></App>
        <Footer />
      </FlexWrapper>
    </React.Suspense>
  ) : (
    <FlexWrapper>
      <Nav path={props.location.pathname} />
      <App element={element} />
      <Footer />
    </FlexWrapper>
  )
}
