import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { Epic } from 'redux-observable'
import { combineLatest, from, merge, of } from 'rxjs'
import {
  catchError,
  concatMap,
  concatMapTo,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { RootState } from '..'
import * as api from '../../api/web3'
import {
  UserLoggedOutError,
  UserRejectedRequestError,
} from '../../api/web3/errors'
import { bn } from '../../features/my-collection/Unbind/utils'
import { web3Config } from './config'
import { emitOnIntervalOrTx } from './unbind.side-effects'
import { ApprovalAmount, Balance, web3Service } from './web3.service'
/**
 * This slice will either be used in l1 or l2 mode depending on
 * which portion of the site the user is currently interacting with.
 *
 * 1. When the user is trying to interact with the TGE portion of the site,
 * we'll need an L1 signer since that's what the user needs to mint tokens.
 *
 * 2. When the user is trying to interact with the dapp, the signer needs to be
 * on L2 since that's where the unbind functionality exists.
 *
 * Other functionality such as l2<->l1 bridging and p2p transfer is all handled
 * on the dokidoki side.
 *
 * One thing to note is that when we're currently in case #2, we'll need to spin up
 * a provider on l1, so we can do things such as check user funds (AZUKI, BND) on l1
 * to give them more information on where the location of their funds are. The location
 * of their momiji tokens (binder cards) can be inferred by the dokidoki api, since
 * its response contains information detailing which network the tokens are held at.
 */

/**
 * The current loading state depending on user actions
 */
export enum SignerLoading {
  IDLE = 'idle',
  // Emitted when a user wants to show the modal
  PENDING = 'pending',
  SELECTED = 'selected',
  SUCCESSFUL = 'successful',
  ERRORED = 'errored',
}

/**
 * The current signer that we're going to interact with
 */
export enum SignerType {
  METAMASK = 'metamask',
  WALLETCONNECT = 'walletconnect',
  NONE = 'none',
}

export interface Web3State {
  prevLoading: SignerLoading
  /**
   * The current web3 state, used for onboarding process
   */
  loading: SignerLoading
  /**
   * The type of signer being used
   */
  type: SignerType
  prevType: SignerType
  /**
   * If the provider is able to currently service
   * web3 requests
   */
  isConnected: boolean
  balances: Balance[]
  approvals: ApprovalAmount[]
  /**
   * The users current ethereum account
   */
  account?: string
  /**
   * The current chainId of the provider
   */
  chainId?: number
  /**
   * The current error, if any
   */
  error: null | string
}

const INITIAL_STATE: Web3State = {
  account: undefined,
  error: null,
  balances: [],
  approvals: [],
  isConnected: false,
  prevLoading: SignerLoading.IDLE,
  loading: SignerLoading.IDLE,
  type: SignerType.NONE,
  prevType: SignerType.NONE,
  chainId: undefined,
}

export const signerSlice = createSlice({
  name: 'web3',
  initialState: INITIAL_STATE as Web3State,
  reducers: {
    signerSelected(
      state,
      { payload }: PayloadAction<{ signerType: SignerType }>,
    ) {
      state.loading = SignerLoading.SELECTED
      state.type = payload.signerType
    },
    accountUnlocked(state, { payload }: PayloadAction<{ account: string }>) {
      state.account = payload.account
    },
    accountChanged(state, { payload }: PayloadAction<{ account: string }>) {
      state.account = payload.account
    },
    chainIdChanged(state, { payload }: PayloadAction<{ chainId: number }>) {
      state.chainId = payload.chainId
    },
    balancesUpdated(
      state,
      { payload }: PayloadAction<{ balances: Balance[] }>,
    ) {
      state.balances = payload.balances
    },
    approvalsUpdated(
      state,
      { payload }: PayloadAction<{ approvals: ApprovalAmount[] }>,
    ) {
      state.approvals = payload.approvals
    },
    connectionChanged(
      state,
      { payload }: PayloadAction<{ isConnected: boolean }>,
    ) {
      state.isConnected = payload.isConnected
    },
    logout() {
      return INITIAL_STATE
    },
    updateIdle(state) {
      state.prevLoading = SignerLoading.IDLE
      state.loading = SignerLoading.IDLE
    },
    updateCancelled(state) {
      state.loading = state.prevLoading
      state.type = state.prevType
    },
    updatePending(state) {
      state.loading = SignerLoading.PENDING
    },
    updateError(state) {
      state.prevLoading = SignerLoading.ERRORED
      state.loading = SignerLoading.ERRORED
    },
    updateSuccessful(state) {
      state.prevLoading = SignerLoading.SUCCESSFUL
      state.loading = SignerLoading.SUCCESSFUL
      state.prevType = state.type
    },
  },
})

export const selectSelf = (state: RootState) => state.web3.signer
export const selectWeb3Loading = createSelector(
  selectSelf,
  (state) => state.loading,
)

export const selectSignerChain = createSelector(selectSelf, (state) =>
  state.chainId ? web3Service.getChainFor(state.chainId) : null,
)
export const selectIsL2 = createSelector(selectSignerChain, (s) => {
  if (!s) {
    return null
  }
  return s.chainId === web3Service.getRequiredSignerChainId()
})
export const selectWeb3SignerType = createSelector(
  selectSelf,
  (state) => state.type,
)
export const selectWeb3Account = createSelector(
  selectSelf,
  (state) => state.account,
)
export const selectWeb3Balances = createSelector(
  selectSelf,
  (state) => state.balances,
)
export const selectWeb3BalancesLoaded = createSelector(
  selectWeb3Balances,
  (s) => s.length > 0,
)
export const selectWeb3Approvals = createSelector(
  selectSelf,
  (state) => state.approvals,
)

export enum Web3ApprovalState {
  IDLE,
  PENDING,
  APPROVE_AZUKI,
  APPROVE_MOMIJI,
  SUCCESSFUL,
}
export const selectWeb3ApprovalState = createSelector(
  selectWeb3Approvals,
  selectWeb3Loading,
  (_: RootState, azukiBurnAmount: string) => bn(azukiBurnAmount),
  (approvals, loading, azukiToBurn) => {
    if (loading !== SignerLoading.SUCCESSFUL) {
      return Web3ApprovalState.IDLE
    }
    if (approvals.length != 2) {
      return Web3ApprovalState.PENDING
    }
    const [azukiApproval, momijiApproval] = approvals

    if (azukiToBurn.gt(azukiApproval.amount)) {
      return Web3ApprovalState.APPROVE_AZUKI
    }
    if (Number(momijiApproval.amount) === 0) {
      return Web3ApprovalState.APPROVE_MOMIJI
    }
    return Web3ApprovalState.SUCCESSFUL
  },
)

export const selectAzukiBalance = createSelector(
  selectWeb3Balances,
  (state) => {
    return state
      .filter((t) => t.symbol === 'AZUKI')
      .sort((a, b) => a.chainId - b.chainId)
  },
)

export const selectL2AzukiBalance = createSelector(
  selectWeb3Balances,
  (state) => {
    return (
      state.find(
        (t) => t.symbol === 'AZUKI' && t.chainId === web3Config.L2.CHAIN_ID,
      )?.balance ?? '0'
    )
  },
)

export const selectL1NativeTokenBalance = createSelector(
  selectWeb3Balances,
  (state) => {
    const l1NativeToken = web3Service.getConfigForLayerNative({ layer: 'L1' })
    const opposite = state.find(
      (b) => b.symbol === 'NATIVE' && b.chainId === web3Config.L2.CHAIN_ID,
    )

    const balance = state.find(
      (b) =>
        b.symbol === l1NativeToken.symbol &&
        b.chainId === l1NativeToken.chainId &&
        b.decimals === l1NativeToken.decimals,
    )

    return [balance, opposite].map((b) => ({ ...b, symbol: 'ETH' }))
  },
)

export const selectL2NativeTokenBalance = createSelector(
  selectWeb3Balances,
  (state) => {
    const l1NativeToken = web3Service.getConfigForLayerNative({ layer: 'L2' })
    const opposite = state.find(
      (b) => b.symbol === 'NATIVE' && b.chainId === web3Config.L1.CHAIN_ID,
    )

    const balance = state.find(
      (b) =>
        b.symbol === l1NativeToken.symbol &&
        b.chainId === l1NativeToken.chainId &&
        b.decimals === l1NativeToken.decimals,
    )

    return [opposite, balance].map((b) => ({ ...b, symbol: 'MATIC' }))
  },
)

export const selectBNDTokenBalances = createSelector(
  selectWeb3Balances,
  (state) => {
    const bndToken = web3Service.getConfigForToken('BND')
    const balance = state.filter((b) => b.symbol === bndToken[0].symbol)

    return balance
  },
)

export const selectWeb3FormattedBalances = createSelector(
  selectWeb3Balances,
  (state) => {
    return state.map((b) => ({
      ...b,
      balance: ethers.utils.formatUnits(b.balance, b.decimals),
    }))
  },
)

export const walletLoadingEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$,
) => {
  const {
    chainIdChanged,
    accountUnlocked,
    updateSuccessful,
  } = signerSlice.actions

  const active$ = combineLatest([
    action$.pipe(filter(chainIdChanged.match)),
    action$.pipe(filter(accountUnlocked.match)),
  ]).pipe(
    withLatestFrom(state$),
    filter(([, s]) => selectWeb3Loading(s) !== SignerLoading.SUCCESSFUL),
    mapTo(updateSuccessful()),
  )

  return active$
}

export const walletSelectedEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const {
    accountChanged,
    chainIdChanged,
    connectionChanged,
    signerSelected,
    logout,
    updateError,
  } = signerSlice.actions
  const {
    accountsChangedStream,
    connectedStream,
    chainChangedStream,
    disconnectedStream,
  } = api

  return action$.pipe(
    filter(signerSelected.match),
    switchMap(
      async ({ payload }) =>
        [payload.signerType, ...(await getSignerAndProvider(payload))] as const,
    ),
    tap(([, provider, signer]) => {
      web3Service.setSignerProvider(provider)
      web3Service.setSigner(signer)
    }),
    switchMap(([signerType, provider]) => {
      const stop$ = action$.pipe(filter(logout.match))
      const chainChanged = chainChangedStream(provider).pipe(
        map((chainId) => chainIdChanged({ chainId })),
      )
      const connected = connectedStream(provider).pipe(
        concatMap((chainId) => {
          const changes: AnyAction[] = [
            connectionChanged({
              isConnected: true,
            }),
          ]
          if (chainId) {
            changes.unshift(chainIdChanged({ chainId }))
          }

          return of(...changes)
        }),
      )
      const disconnected = disconnectedStream(provider).pipe(
        concatMapTo(of(connectionChanged({ isConnected: false }), logout())),
      )

      const accountsUnlocked = accountsUnlockedActionStream(
        provider,
        signerType,
      )
      const accountsChanged = accountsChangedStream(provider).pipe(
        map((account) => accountChanged({ account })),
        catchError((e) => {
          console.error('[walletSelectedEpic][accountsChangedStream]', e)
          return e instanceof UserLoggedOutError
            ? of(logout())
            : of(updateError())
        }),
      )

      return merge(
        connected,
        disconnected,
        accountsChanged,
        accountsUnlocked,
        chainChanged,
      ).pipe(takeUntil(stop$))
    }),
  )
}

export const updateWalletBalancesEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$,
) => {
  const {
    logout,
    accountUnlocked,
    accountChanged,
    balancesUpdated,
    approvalsUpdated,
  } = signerSlice.actions

  const stop$ = action$.pipe(filter(logout.match))

  return action$.pipe(
    filter((v) => accountUnlocked.match(v) || accountChanged.match(v)),
    withLatestFrom(state$),
    map(([, s]) => selectWeb3Account(s)),
    switchMap((account) => {
      if (!account) {
        throw Error('No account found to scrape tokens')
      }

      const shouldUpdate$ = emitOnIntervalOrTx(action$)
      // turn this into a tick based watcher
      // so we can have network dependent updates be based off of
      // the same tick, like unbind price updates
      const updateBalance$ = shouldUpdate$.pipe(
        mergeMap(() => web3Service.updateBalances(account)),
        distinctUntilChanged(compareBalances),
        map((balances) => balancesUpdated({ balances })),
        takeUntil(stop$),
      )

      const updateApproval$ = shouldUpdate$.pipe(
        mergeMap(() => web3Service.updateApprovals(account)),
        distinctUntilChanged(compareApprovals),
        map((approvals) => approvalsUpdated({ approvals })),
        takeUntil(stop$),
      )

      return merge(updateApproval$, updateBalance$)
    }),
  )
}

/**
 * Returns true if both balance sets are the same
 * @param a First balance to compare
 * @param b Second balance to compare
 */
function compareBalances(a: Balance[], b: Balance[]): boolean {
  return a.reduce<boolean>((prev, { symbol, balance, decimals, chainId }) => {
    // Check if there was a token in the previous set that matches the
    // current token
    const matchingBalance = b.find(
      (t) =>
        t.symbol === symbol &&
        t.chainId === chainId &&
        t.balance === balance &&
        t.decimals === decimals,
    )

    return prev && !!matchingBalance
  }, true)
}

function compareApprovals(a: ApprovalAmount[], b: ApprovalAmount[]): boolean {
  return a.reduce<boolean>((prev, { symbol, amount, decimals, chainId }) => {
    // Check if there was a token in the previous set that matches the
    // current token
    const matchingAmount = b.find(
      (t) =>
        t.symbol === symbol &&
        t.chainId === chainId &&
        t.amount === amount &&
        t.decimals === decimals,
    )

    return prev && !!matchingAmount
  }, true)
}

function getSignerAndProvider(payload: { signerType: SignerType }) {
  return payload.signerType === SignerType.METAMASK
    ? api.getMetamaskProvider()
    : api.getWalletConnectProvider()
}

function accountsUnlockedActionStream(
  provider: ethers.providers.Web3Provider,
  signerType: SignerType,
) {
  const { accountUnlocked, updateError, updateCancelled } = signerSlice.actions
  if (signerType === SignerType.METAMASK) {
    return from(api.requestMetamaskAccounts(provider)).pipe(
      mergeMap((account) => of(accountUnlocked({ account }))),
      catchError((e) => {
        console.error(
          '[accountsUnlockedActionStream][requestMetamaskAccounts]',
          e,
        )
        if (e instanceof UserRejectedRequestError) {
          return of(updateCancelled())
        }
        return of(updateError())
      }),
    )
  } else if (signerType === SignerType.WALLETCONNECT) {
    return from(api.requestWalletConnectAccounts(provider)).pipe(
      mergeMap((account) => of(accountUnlocked({ account }))),
      catchError((e) => {
        console.error(
          '[accountsUnlockedActionStream][requestWalletConnectAccounts]',
          e,
        )
        return e?.message === 'User closed modal'
          ? of(updateCancelled())
          : of(updateError())
      }),
    )
  } else {
    throw Error('[accountsUnlockedActionStream] Invalid signer type provided')
  }
}
