import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import * as graph from '../../api/graph'
import { bn } from '../../features/my-collection/Unbind/utils'

/**
 * The current loading state depending on user actions
 */
export enum Loading {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESSFUL = 'successful',
  ERRORED = 'errored',
}

interface OwnershipState {
  owner?: string
  loading: Loading
  cbsById: {
    [tokenId: string]: OwnedCb
  }
  ids: string[]
}
const OWNERSHIP_INITIAL_STATE: OwnershipState = {
  loading: Loading.IDLE,
  cbsById: {},
  ids: [],
}

interface OwnedCb {
  tokenId: string
  totalBalance: number
  balanceByLayer: {
    L1: number
    L2: number
  }
}

export const ownershipSlice = createSlice({
  name: 'web3/ownership',
  initialState: OWNERSHIP_INITIAL_STATE,
  reducers: {
    INIT_POLLING: (state, { payload }: PayloadAction<string>) => {
      state.owner = payload
      state.loading = Loading.PENDING
    },

    POLL_UPDATED: (
      state,
      {
        payload,
      }: PayloadAction<{
        nfts: (graph.NFT & { layer: 'L1' | 'L2' })[]
      }>,
    ) => {
      state.loading = Loading.SUCCESSFUL
      const cbsById = payload.nfts.reduce<Record<string, OwnedCb>>(
        (prev, next) => {
          const exists = prev[next.tokenId]
          const nextBalance = Number(next.ownership.balance)
          if (exists) {
            exists.balanceByLayer[next.layer] = nextBalance
            exists.totalBalance += nextBalance

            return prev
          }

          const balance = { [next.layer]: nextBalance }
          prev[next.tokenId] = {
            totalBalance: nextBalance,
            tokenId: next.tokenId,
            balanceByLayer: balance as any,
          }

          return prev
        },
        {},
      )

      state.cbsById = cbsById
      state.ids = Object.keys(cbsById)
    },
    POLL_FAILED: (_state) => {
      // state.loading = Loading.ERRORED
    },

    POLL_STOPPED: () => {
      return OWNERSHIP_INITIAL_STATE
    },
  },
})

export const selectOwnership = (state: RootState) => state.web3.ownership
export const selectOwnershipLoading = createSelector(
  selectOwnership,
  (state) => state.loading,
)
export const selectOwnedById = createSelector(
  selectOwnership,
  (state) => state.cbsById,
)
export const selectCBstats = (state: RootState) => state.web3.cbStats
export const selectCBstatsLoading = createSelector(
  selectCBstats,
  (state) => state.loading,
)

export const selectCBStatsById = createSelector(
  selectCBstats,
  (state) => state.cbsById,
)

export type LiveNft = graph.RawStaticNft & graph.Chainbinder
export type OwnedNft = LiveNft & OwnedCb
export enum NftType {
  STATIC, // straight from gatsby, static data
  MERGED, // has been updated with live data
  OWNED, // has been updated with owner data
}
export type StaticKeyedNft = graph.RawStaticNft & { type: NftType.STATIC }
export type LiveKeyedNft = LiveNft & { type: NftType.MERGED }
export type OwnedKeyedNft = OwnedNft & { type: NftType.OWNED }
export type Nft = StaticKeyedNft | LiveKeyedNft | OwnedKeyedNft

export const selectCB = createSelector(
  selectCBStatsById,
  selectOwnedById,
  (_: RootState, staticNft: graph.RawStaticNft) => staticNft,
  (statsById, ownedById, staticNft) => {
    return mergeNft(staticNft, statsById, ownedById)
  },
)

export interface NftCollection {
  /**
   * The total value in WEI of the non-burned cards
   */
  collectionValue: string
  /**
   * The total value in WEI of the user's owned cards
   */
  ownedCollectionValue: string
  /**
   * The total number nfts owned by the user, including secret nfts, 1 per card type
   */
  totalNftsOwned: number
  /**
   * The total number of NFTs, excluding secret nfts, 1 per card type
   */
  totalNfts: number
  /**
   * The total number of NFT's burned, excluding secret NFTs
   */
  totalBurned: number
  /**
   * The number of NFTs the user owns, keyed by rarity, includes secrets
   */
  ownedNftsByRarity: Record<string, number>
  /**
   * The number of unburned NFTs keyed by rarity, excludes secrets
   */
  nftsByRarity: Record<string, number>

  /**
   * All NFT cards, discriminatable by the "type" property
   */
  nfts: Nft[]
}

export const selectNftCollection = createSelector(
  selectOwnedById,
  selectCBStatsById,
  (_: RootState, staticNfts: graph.RawStaticNft[]) => staticNfts,
  (ownedById, statsById, staticNfts) => {
    const staticById = staticNfts.reduce((prev, next) => {
      prev[next.tokenId] = next
      return prev
    }, {} as Record<string, graph.RawStaticNft>)
    const staticIds = staticNfts.map((s) => s.tokenId)
    const ownedNftsByRarity: Record<string, number> = {}
    const nftsByRarity: Record<string, number> = {}
    let collectionValue = bn(0)
    let ownedCollectionValue = bn(0)
    let totalBurned = 0
    let totalNftsOwned = 0
    const nfts: Nft[] = staticIds.map(
      mergeNftById(staticById, statsById, ownedById),
    )

    nfts.forEach((nft) => {
      switch (nft.type) {
        case NftType.OWNED:
        case NftType.MERGED: {
          if (nft.type === NftType.OWNED) {
            const totalBalance = Number(nft.totalBalance)
            if (ownedNftsByRarity[nft.rarityType]) {
              ownedNftsByRarity[nft.rarityType] += totalBalance
            } else {
              ownedNftsByRarity[nft.rarityType] = totalBalance
            }
            if (!nft.rarity) {
              throw Error(`Card does not have rarity ${JSON.stringify(nft)}`)
            }
            const perNftValue = bn(nft.rarity.ethBalance).div(
              nft.rarity.nftAmount,
            )
            const value = perNftValue.mul(totalBalance)
            totalNftsOwned++
            ownedCollectionValue = ownedCollectionValue.add(value)
          }

          if (nft.isSecret) {
            return
          }
          if (!nftsByRarity[nft.rarityType]) {
            nftsByRarity[nft.rarityType] = Number(nft.rarity.nftAmount)
            collectionValue = collectionValue.add(nft.rarity.ethBalance)
            console.log(nft.rarityType, nft, nftsByRarity)
          }
          totalBurned += Number(nft.amountBurned)
          return
        }
        case NftType.STATIC: {
          break
        }
      }
    })

    const ownedCbs: NftCollection = {
      nfts,

      totalBurned,
      totalNfts: Object.values(staticById).filter((s) => !s.isSecret).length,

      collectionValue: collectionValue.toString(),
      nftsByRarity,

      totalNftsOwned,
      ownedCollectionValue: ownedCollectionValue.toString(),
      ownedNftsByRarity,
    }
    return ownedCbs
  },
)

interface CBStatsState {
  loading: Loading
  cbsById: { [tokenId: string]: graph.Chainbinder }
  ids: string[]
}
const CB_STATS_INITAL_STATE: CBStatsState = {
  loading: Loading.IDLE,
  cbsById: {},
  ids: [],
}
export const cbStatsSlice = createSlice({
  name: 'web3/collection',
  initialState: CB_STATS_INITAL_STATE,
  reducers: {
    INIT_POLLING_REQUESTED: (state) => {
      state.loading = Loading.PENDING
    },
    POLL_UPDATED: (
      state,
      {
        payload,
      }: PayloadAction<{
        chainbinders: graph.Chainbinder[]
      }>,
    ) => {
      state.loading = Loading.SUCCESSFUL
      state.cbsById = Object.fromEntries(
        payload.chainbinders.map((b) => [b.id, b]),
      )
      state.ids = payload.chainbinders.map((c) => c.id)
    },
    POLL_FAILED: (_state) => {
      // state.loading = Loading.ERRORED
    },
    POLL_STOPPED: () => {
      return CB_STATS_INITAL_STATE
    },
  },
})

function mergeNftById(
  staticById: Record<string, graph.RawStaticNft>,
  statsById: { [tokenId: string]: graph.Chainbinder },
  ownedById: { [tokenId: string]: OwnedCb },
): (value: string) => Nft {
  return (id): Nft => {
    const staticNft = staticById[id]
    if (!staticNft) {
      throw Error(`Could not find static nft for ${id}`)
    }

    return mergeNft(staticNft, statsById, ownedById)
  }
}

function mergeNft(
  staticNft: graph.RawStaticNft,
  statsById: { [tokenId: string]: graph.Chainbinder },
  ownedById: { [tokenId: string]: OwnedCb },
): Nft {
  const mergedNft = statsById[staticNft.tokenId]
  const ownedNft = ownedById[staticNft.tokenId]

  if (mergedNft) {
    if (ownedNft && ownedNft.totalBalance > 0) {
      return {
        ...staticNft,
        ...mergedNft,
        ...ownedNft,
        type: NftType.OWNED,
      }
    } else {
      return { ...staticNft, ...mergedNft, type: NftType.MERGED }
    }
  } else {
    return { ...staticNft, type: NftType.STATIC }
  }
}
