import { commify, formatUnits } from '@ethersproject/units'
import { BigNumber, BigNumberish } from 'ethers'

export function etherToWei(val: BigNumber) {
  return val.mul(BigNumber.from(10).pow(18))
}

export function fEth(amount?: BigNumberish, precision = 18) {
  if (!amount) {
    return '0'
  }
  return truncate(formatUnits(amount, 18), precision)
}

export function fEthUsd(amount: BigNumberish, ethUsd: number) {
  return commify(truncate(Number(fEth(amount)) * ethUsd))
}

export function bn(val: BigNumberish) {
  return BigNumber.from(val)
}

export function truncate(bal: string | number, precision: number = 2): string {
  if (typeof bal === 'number') {
    bal = bal.toString()
  }
  if (!bal.includes('.')) {
    return bal
  }
  const parts = bal.split('.')
  if (precision === 0) {
    return `${parts[0]}`
  }
  return `${parts[0]}.${parts[1].substr(0, precision)}`
}
