import { combineReducers } from 'redux'
import { cbStatsEpic, ownershipEpic } from './collection.side-effects'
import { cbStatsSlice, ownershipSlice } from './collection.state'
import {
  signerSlice,
  updateWalletBalancesEpic,
  walletLoadingEpic,
  walletSelectedEpic,
} from './signer'
import {
  unbindGasSlice,
  unbindStatsSlice,
  unbindTxEpic,
  unbindTxSlice,
  updateGasEpic,
  updateGasPriceEpic,
  updateUnbindStatsEpic,
} from './unbind.side-effects'

export const epics = [
  updateWalletBalancesEpic,
  updateGasEpic,
  updateGasPriceEpic,
  unbindTxEpic,
  updateUnbindStatsEpic,
  walletLoadingEpic,
  walletSelectedEpic,
  cbStatsEpic,
  ownershipEpic,
]

export const reducer = combineReducers({
  unbindStats: unbindStatsSlice.reducer,
  unbindGas: unbindGasSlice.reducer,
  unbindTx: unbindTxSlice.reducer,
  signer: signerSlice.reducer,
  cbStats: cbStatsSlice.reducer,
  ownership: ownershipSlice.reducer,
})
