import { MomijiToken, MomijiToken__factory } from '../../ethers-contracts'
import { web3Config } from './config'
export class MomijiService {
  private contract: MomijiToken
  constructor(...params: Parameters<typeof MomijiToken__factory['connect']>) {
    this.contract = MomijiToken__factory.connect(...params)
  }

  public async getAllowance(fromAccount: string) {
    const allowance = await this.contract.isApprovedForAll(
      fromAccount,
      web3Config.UNBIND_ADDRESS,
    )
    return allowance ? 1 : 0
  }

  public async tx() {
    return await this.contract.setApprovalForAll(
      web3Config.UNBIND_ADDRESS,
      true,
    )
  }

  public async estimateGas() {
    return this.contract.estimateGas.setApprovalForAll(
      web3Config.UNBIND_ADDRESS,
      true,
    )
  }
}
