import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const apiKey = process.env.GATSBY_APP_NEXUS_API_KEY
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // debug: process.env.NODE_ENV !== 'production',
    ns: ['default'],
    defaultNS: 'default',
    react: {
      useSuspense: !(typeof window === 'undefined'),
    },
    supportedLngs: ['en', 'zh', 'ja'],

    backend: {
      loadPath: loadPath,
    },
  })
