export const web3Config = {
  APP_MODE: required(process.env.GATSBY_APP_MODE, 'GATSBY_APP_MODE') as
    | 'LGE'
    | 'DAPP',
  UNBIND_ADDRESS: required(
    process.env.GATSBY_APP_UNBIND_ADDRESS,
    'GATSBY_APP_UNBIND_ADDRESS',
  ),
  MOMIJI_ADDRESS: required(
    process.env.GATSBY_APP_MOMIJI_MATIC_ADDRESS,
    'GATSBY_APP_MOMIJI_MATIC_ADDRESS',
  ),
  MULTI_USD_URL: required(
    process.env.GATSBY_APP_MULTI_USD_URL,
    'GATSBY_APP_MULTI_USD_URL',
  ),
  L1: {
    RPC_URL: required(
      process.env.GATSBY_APP_L1_RPC_URL,
      'GATSBY_APP_L1_RPC_URL',
    ),
    CHAIN_ID: parseInt(
      required(process.env.GATSBY_APP_L1_CHAIN_ID, 'GATSBY_APP_L1_CHAIN_ID') ||
        '',
    ),
    GQL_ENDPOINT: required(
      process.env.GATSBY_APP_L1_GQL_ENDPOINT,
      'GATSBY_APP_L1_GQL_ENDPOINT',
    ),
  },
  L2: {
    RPC_URL: required(
      process.env.GATSBY_APP_L2_RPC_URL,
      'GATSBY_APP_L2_RPC_URL',
    ),
    CHAIN_ID: parseInt(
      required(process.env.GATSBY_APP_L2_CHAIN_ID, 'GATSBY_APP_L2_CHAIN_ID') ||
        '',
    ),
    GQL_ENDPOINT: required(
      process.env.GATSBY_APP_L2_GQL_ENDPOINT,
      'GATSBY_APP_L2_GQL_ENDPOINT',
    ),
  },

  TOKENS_BY_SYMBOL: {
    AZUKI: {
      L2: required(
        process.env.GATSBY_APP_AZUKI_MATIC_ADDRESS,
        'GATSBY_APP_AZUKI_MATIC_ADDRESS',
      ),
      L1: required(
        process.env.GATSBY_APP_AZUKI_ETH_ADDRESS,
        'GATSBY_APP_AZUKI_ETH_ADDRESS',
      ),
    },
    NATIVE: {
      L1: required(
        process.env.GATSBY_APP_MATIC_ETH_ADDRESS,
        'GATSBY_APP_MATIC_ETH_ADDRESS',
      ),
      L2: required(
        process.env.GATSBY_APP_WETH_MATIC_ADDRESS,
        'GATSBY_APP_WETH_MATIC_ADDRESS',
      ),
    },
  },
}

function required(str: string | undefined, envKey: string): string {
  if (!str) {
    throw Error(`Undefined env var ${envKey}`)
  }
  return str
}

export type TokenSymbol = keyof typeof web3Config['TOKENS_BY_SYMBOL']

export function isValidL1ChainId(chainId: number): boolean {
  return chainId === web3Config.L1.CHAIN_ID
}
export function isValidL2ChainId(chainId: number): boolean {
  return chainId === web3Config.L2.CHAIN_ID
}
