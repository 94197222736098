/**
 * https://github.com/NoahZinsmeister/web3-react/blob/v6/packages/core/src/manager.ts
 */
export class UnsupportedChainIdError extends Error {
  public constructor(
    unsupportedChainId: number,
    supportedChainIds?: readonly number[],
  ) {
    super()
    this.name = this.constructor.name
    this.message = `Unsupported chain id: ${unsupportedChainId}. Supported chain ids are: ${supportedChainIds}.`
  }
}

/**
 * https://github.com/NoahZinsmeister/web3-react/blob/v6/packages/injected-connector/src/index.ts
 */
export class NoEthereumProviderError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Ethereum provider was found on window.ethereum.'
  }
}

export class UserLoggedOutError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user has logged out'
  }
}

/**
 * https://github.com/NoahZinsmeister/web3-react/blob/v6/packages/injected-connector/src/index.ts
 */
export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}

/**
 * TODO: Maybe use eth-rpc-error package to give more informative
 * error information back to the user
 *
 * https://docs.metamask.io/guide/ethereum-provider.html#errors
 */
export interface ProviderRpcError extends Error {
  message: string
  code: number
  data?: unknown
}
