import { getPrices } from '../../api/web3'
import {
  ChainbindersMelt,
  ChainbindersMelt__factory,
} from '../../ethers-contracts'

export class UnbindService {
  private contract: ChainbindersMelt
  constructor(
    ...params: Parameters<typeof ChainbindersMelt__factory['connect']>
  ) {
    this.contract = ChainbindersMelt__factory.connect(...params)
  }

  public async init() {
    const rest = await this.poll()
    return {
      ...rest,
    }
  }

  public async poll() {
    const [/*{ AZUKI, ETH, MATIC }, */isStarted] = await Promise.all([
      /*getPrices().toPromise(),*/
      this.contract.isStarted(),
    ])

    return {
      isStarted,
      azukiUsdPrice: 0,
      maticUsdPrice: 1,
      ethUsdPrice: 1850,
    }
  }

  public async tx(cardId: string, count: number) {
    if (count > 10) {
      throw Error(`Cannot burn more than 10 cards, got ${count}`)
    }
    const tx = await this.contract.melt(cardId, count)
    return tx
  }

  public estimateGas(cardId: string, count: number) {
    return this.contract.estimateGas.melt(cardId, count)
  }
}
