/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Provider } from 'react-redux'
import './global.scss'
import reportWebVitals from './reportWebVitals'
import { createStore } from './store'
import './translations'

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()

  return <Provider store={store}>{element}</Provider>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
