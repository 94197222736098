import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TelegramIcon from '../../images/footer/telegram-icon.svg'
import TwitterIcon from '../../images/footer/twitter-icon.svg'
import YoutubeIcon from '../../images/footer/youtube-icon.svg'
import './Footer.scss'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <StyledFooter>
      <StyledCopyright className="footer-copyright">
        {t('dokidokiCp')}
      </StyledCopyright>
      <StyledIconContainer>
        <a
          href="https://twitter.com/Chainbinders"
          target="_blank"
          rel="noreferrer"
        >
          <StyledIcon src={TwitterIcon} alt="twitter icon" />
        </a>
        <a href="https://t.me/ddnfg" target="_blank" rel="noreferrer">
          <StyledIcon src={TelegramIcon} alt="discord icon" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCHKQaCYkq3zuHOi77aJ3cUA"
          target="_blank"
          rel="noreferrer"
        >
          <StyledIcon src={YoutubeIcon} alt="youtube icon" />
        </a>
      </StyledIconContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  height: 5rem;
  background: black;

  @media (min-width: 200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
`

const StyledCopyright = styled.p`
  color: #ffffff;
  align-self: center;
  white-space: nowrap;

  @media (min-width: 200px) {
    font-size: 0.572rem;
  }

  @media (min-width: 750px) {
    font-size: 0.725rem;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  }
`

const StyledIcon = styled.img`
  width: 3rem;
  height: auto;
`

const StyledIconContainer = styled.div`
  @media (min-width: 200px) {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  @media (min-width: 1200px) {
    width: 100%;
  }
`
