import * as api from '../../api/graph'
import { web3Config } from './config'
export class CollectionService {
  private endpoints = [
    web3Config.L1.GQL_ENDPOINT,
    web3Config.L2.GQL_ENDPOINT,
  ] as const

  public async pollStats() {
    const data = await api.queryForChainbindersStats(this.endpoints[1])

    return data.chainbinders
  }

  public async pollBalances(account: string) {
    const data = await Promise.all(
      this.endpoints.map((e) => api.queryForBalances(e, account)),
    )
    const flattened = data.flatMap((d, i) =>
      d.nfts.map((nft) => ({ ...nft, layer: i === 0 ? 'L1' : 'L2' } as const)),
    )

    return flattened
  }
}
