// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chainbinders-binder-key-tsx": () => import("./../../../src/pages/chainbinders/{Binder.key}.tsx" /* webpackChunkName: "component---src-pages-chainbinders-binder-key-tsx" */),
  "component---src-pages-chainbinders-index-tsx": () => import("./../../../src/pages/chainbinders/index.tsx" /* webpackChunkName: "component---src-pages-chainbinders-index-tsx" */),
  "component---src-pages-game-theory-index-tsx": () => import("./../../../src/pages/game-theory/index.tsx" /* webpackChunkName: "component---src-pages-game-theory-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-my-collection-binder-nft-token-id-tsx": () => import("./../../../src/pages/my-collection/{BinderNFT.tokenId}.tsx" /* webpackChunkName: "component---src-pages-my-collection-binder-nft-token-id-tsx" */),
  "component---src-pages-my-collection-index-tsx": () => import("./../../../src/pages/my-collection/index.tsx" /* webpackChunkName: "component---src-pages-my-collection-index-tsx" */),
  "component---src-pages-tokenomics-index-tsx": () => import("./../../../src/pages/tokenomics/index.tsx" /* webpackChunkName: "component---src-pages-tokenomics-index-tsx" */),
  "component---src-pages-universe-gallery-index-tsx": () => import("./../../../src/pages/universe/gallery/index.tsx" /* webpackChunkName: "component---src-pages-universe-gallery-index-tsx" */),
  "component---src-pages-universe-lore-index-tsx": () => import("./../../../src/pages/universe/lore/index.tsx" /* webpackChunkName: "component---src-pages-universe-lore-index-tsx" */)
}

