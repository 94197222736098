import { ethers } from 'ethers'
import { addMaticChainToMetamask } from '../../api/web3'
import { ERC20__factory } from '../../ethers-contracts'
import { AzukiService } from './azuki.service'
import chains from './chains.json'
import { TokenSymbol, web3Config } from './config'
import { MomijiService } from './momiji.service'
export interface Balance {
  symbol: string
  chainId: number
  balance: string
  decimals: number
}

export interface ApprovalAmount {
  symbol: string
  chainId: number
  amount: string
  decimals: number
}

interface LayerTokenConfig {
  layer: 'L1' | 'L2'
  symbol: TokenSymbol
}
interface LayerConfig {
  layer: 'L1' | 'L2'
}

export class Web3Service {
  private signer?: ethers.Signer
  private signerProvider?: ethers.providers.Web3Provider

  private readonly layers = ['L1', 'L2'] as const
  public l1Provider: ethers.providers.StaticJsonRpcProvider = new ethers.providers.StaticJsonRpcProvider(
    web3Config.L1.RPC_URL,
  )
  public l2Provider: ethers.providers.StaticJsonRpcProvider = new ethers.providers.StaticJsonRpcProvider(
    web3Config.L2.RPC_URL,
  )

  public setSignerProvider(provider: ethers.providers.Web3Provider) {
    this.signerProvider = provider
  }

  public getSignerProvider(): ethers.providers.Web3Provider {
    if (!this.signerProvider) {
      throw Error('No signer Provider has been set')
    }
    return this.signerProvider
  }
  public setSigner(signer: ethers.Signer) {
    this.signer = signer
  }

  public getSigner(): ethers.Signer {
    if (!this.signer) {
      throw Error('No signer has been set')
    }
    return this.signer
  }

  public addMaticNetworkToMetamask() {
    return addMaticChainToMetamask(this.getSignerProvider())
  }

  public getChainFor(chainId: number) {
    const chain = chains.find((c) => c.chainId === chainId)
    if (!chain) {
      throw Error(
        `Could not lookup required chain for ${this.getRequiredSignerChainId()}`,
      )
    }
    return chain
  }

  public getRequiredSignerChain() {
    return this.getChainFor(this.getRequiredSignerChainId())
  }

  public getRequiredSignerChainId() {
    return web3Config[this.getRequiredSignerLayer()].CHAIN_ID
  }

  public getRequiredSignerLayer(): 'L1' | 'L2' {
    return web3Config.APP_MODE === 'LGE' ? 'L1' : 'L2'
  }

  public async updateBalances(account: string): Promise<Balance[]> {
    const [erc20Balances, nativeBalances] = await Promise.all([
      Promise.all(this.updateErc20Balances(account)),
      Promise.all(this.updateNativeBalances(account)),
    ])

    return erc20Balances.concat(nativeBalances)
  }

  public async updateApprovals(account: string): Promise<ApprovalAmount[]> {
    const [, azukiConfig] = this.getConfigForToken('AZUKI')
    const momijiConfig = {
      ...this.getConfigForLayer({ layer: 'L2' }),
      symbol: 'MOMIJI',
      address: web3Config.MOMIJI_ADDRESS,
    }

    const azukiSerivce = new AzukiService(
      azukiConfig.address,
      azukiConfig.provider,
    )
    const momijiSerivce = new MomijiService(
      momijiConfig.address,
      momijiConfig.provider,
    )

    const [azukiAllowanceAmt, momijiAllowanceAmt] = await Promise.all([
      azukiSerivce.getAllowance(account),
      momijiSerivce.getAllowance(account),
    ])

    const azukiAllowance: ApprovalAmount = {
      amount: azukiAllowanceAmt.toString(),
      chainId: azukiConfig.chainId,
      decimals: 18,
      symbol: azukiConfig.symbol,
    }
    const momijiAllowance: ApprovalAmount = {
      amount: momijiAllowanceAmt.toString(),
      chainId: momijiConfig.chainId,
      decimals: 0,
      symbol: momijiConfig.symbol,
    }

    return [azukiAllowance, momijiAllowance]
  }

  private updateErc20Balances(account: string): Promise<Balance>[] {
    return (['AZUKI', 'NATIVE'] as const)
      .flatMap((symbol) => this.getConfigForToken(symbol))
      .map(({ address, provider, chainId, symbol }) => ({
        contract: ERC20__factory.connect(address, provider),
        symbol,
        chainId,
      }))
      .map(async (t) => {
        const balance = await t.contract
          .balanceOf(account)
          .then((x) => x.toString())
          .catch((e) => {
            console.error(
              `[updateErc20Balances] Failed to get balance for ${t.symbol} on ${
                this.getChainFor(t.chainId).name
              }: ${e.message}`,
            )
            return '0'
          })
        const decimals = await t.contract.decimals().catch((e) => {
          console.error(
            `[updateErc20Balances] Failed to get decimals for ${t.symbol} on ${
              this.getChainFor(t.chainId).name
            }: ${e.message}`,
          )
          return 18
        })

        const merged: Balance = {
          balance,
          symbol: t.symbol,
          decimals,
          chainId: t.chainId,
        }
        return merged
      })
  }

  private updateNativeBalances(account: string): Promise<Balance>[] {
    return this.getConfigForNative().map(
      async ({ chainId, provider, symbol, decimals }) => ({
        decimals,
        symbol,
        balance: (await provider.getBalance(account)).toString(),
        chainId,
      }),
    )
  }
  public getConfigForLayerNative({ layer }: LayerConfig) {
    const chain = chains.find((c) => c.chainId === web3Config[layer].CHAIN_ID)
    return {
      ...this.getConfigForLayer({ layer }),
      decimals: chain?.nativeCurrency.decimals ?? 18,
      symbol: chain?.nativeCurrency.symbol ?? 'N/A',
    }
  }

  public getConfigForToken(symbol: TokenSymbol) {
    return this.layers.map((layer) =>
      this.getConfigForLayerToken({ layer, symbol }),
    )
  }

  private getConfigForNative() {
    return this.layers.map((layer) => this.getConfigForLayerNative({ layer }))
  }

  private getConfigForLayerToken({ layer, symbol }: LayerTokenConfig) {
    return {
      ...this.getConfigForLayer({ layer }),
      symbol,
      address: web3Config.TOKENS_BY_SYMBOL[symbol][layer],
    }
  }

  private getConfigForLayer({ layer }: LayerConfig) {
    return {
      chainId: web3Config[layer].CHAIN_ID,
      provider: layer === 'L1' ? this.l1Provider : this.l2Provider,
    }
  }
}

export const web3Service = new Web3Service()
