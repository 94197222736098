import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { combineReducers } from 'redux'
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable'
import { catchError } from 'rxjs/operators'
import * as web3 from './web3'

const epicMiddleware = createEpicMiddleware()

const epics: Epic[] = web3.epics

const rootEpic: Epic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error('[rootEpic][catchError]', error)
      return source
    }),
  )

const middleware = [epicMiddleware]
const rootReducer = combineReducers({
  web3: web3.reducer,
})
export type RootState = ReturnType<typeof rootReducer>

export function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: {},
  })

  epicMiddleware.run(rootEpic)

  return store
}

export type AppDispatch = any
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
