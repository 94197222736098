import { PayloadAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { Epic } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators'
import { RootState } from '..'
import { CollectionService } from './collection.service'
import { cbStatsSlice, ownershipSlice } from './collection.state'
import { signerSlice } from './signer'
import { emitOnIntervalOrTx } from './unbind.side-effects'

export const ownershipEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
) => {
  const ownershipUpdateRegistration$ = action$.pipe(
    filter(
      (a): a is PayloadAction<{ account: string }> =>
        !!(
          signerSlice.actions.accountChanged.match(a) ||
          signerSlice.actions.accountUnlocked.match(a)
        ),
    ),
    map((action) =>
      ownershipSlice.actions.INIT_POLLING(action.payload.account),
    ),
  )

  const ownershipUpdateDeregistration$ = action$.pipe(
    filter(signerSlice.actions.logout.match),
    mapTo(ownershipSlice.actions.POLL_STOPPED()),
  )

  const updateOwnership$ = action$.pipe(
    filter(ownershipSlice.actions.INIT_POLLING.match),
    switchMap(({ payload: ownerAddress }) => {
      const collectionService = new CollectionService()
      const stop$ = action$.pipe(
        filter(ownershipSlice.actions.POLL_STOPPED.match),
      )
      const shouldUpdate$ = emitOnIntervalOrTx(action$)
      return shouldUpdate$.pipe(
        mergeMap(() =>
          from(collectionService.pollBalances(ownerAddress)).pipe(
            map((nfts) => ownershipSlice.actions.POLL_UPDATED({ nfts })),
            catchError((e) => {
              console.error(
                `[ownershipEpic][pollBalances] Failed to poll: ${e.message}`,
              )
              return of(ownershipSlice.actions.POLL_FAILED())
            }),
          ),
        ),
        // should do set equality comparsion instead
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(stop$),
      )
    }),
  )

  return merge(
    ownershipUpdateRegistration$,
    ownershipUpdateDeregistration$,
    updateOwnership$,
  )
}

export const cbStatsEpic: Epic<AnyAction, AnyAction, RootState> = (action$) => {
  return action$.pipe(
    filter(cbStatsSlice.actions.INIT_POLLING_REQUESTED.match),
    switchMap(() => {
      const collectionService = new CollectionService()
      const stop$ = action$.pipe(
        filter(cbStatsSlice.actions.POLL_STOPPED.match),
      )
      const shouldUpdate$ = emitOnIntervalOrTx(action$)
      return shouldUpdate$.pipe(
        mergeMap(() =>
          from(collectionService.pollStats()).pipe(
            map((chainbinders) =>
              cbStatsSlice.actions.POLL_UPDATED({ chainbinders }),
            ),
            catchError((e) => {
              console.error(
                `[cbStatsEpic][pollBalances] Failed to poll: ${e.message}`,
              )
              return of(cbStatsSlice.actions.POLL_FAILED())
            }),
          ),
        ),
        distinctUntilChanged((a, b) => {
          if (a.type !== b.type) {
            return false
          }
          return (
            JSON.stringify(a.payload?.chainbinders ?? []) ===
            JSON.stringify(b.payload?.chainbinders ?? [])
          )
        }),
        takeUntil(stop$),
      )
    }),
  )
}
